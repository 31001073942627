<template>
  <div class="edrea_tm_modalbox" :class="open ? 'opened' : ''">
    <div class="box_inner">
      <div class="close">
        <a href="#" @click="close"><i class="icon-cancel"></i></a>
      </div>
      <div class="description_wrap">
        <div class="about_popup_details">
          <div class="left">
            <div class="left_inner">
              <div class="author">
                <div class="avatar_image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/about/1.jpg"></div>
                </div>
                <div class="short">
                  <h3 class="name">
                    {{ aboutData.firstName }}
                    <span class="coloring">{{ aboutData.lastName }}</span>
                  </h3>
                  <h3 class="job">
                    <AnimationText />
                  </h3>
                </div>
              </div>
              <div class="list">
                <ul>
                  <li>
                    <div class="list_inner">
                      <i class="icon-user"></i>
                      <span
                        >{{ aboutData.firstName }}
                        {{ aboutData.lastName }}</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-calendar"></i>
                      <span>October 14</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-location"></i>
                      <span
                        ><a href="#" class="href_location">{{
                          aboutData.address
                        }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-phone"></i>
                      <span
                        ><a href="#">{{ aboutData.phn }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-mail-1"></i>
                      <span
                        ><a :href="`mailto:${aboutData.email}`">{{
                          aboutData.email
                        }}</a></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <!--<div class="edrea_tm_button full">
                <a href="img/about/1.jpg" download>Download CV</a>
              </div>-->
            </div>
          </div>
          <div class="right">
            <div class="right_inner">
              <div class="biography">
                <div class="about_title">
                  <h3>
                    <span>About <span class="coloring">Me</span></span>
                  </h3>
                </div>
                <div class="text">
  <p>
    Hello everybody! My name is
    <span>{{ aboutData.firstName }} {{ aboutData.lastName }}.</span> I have a diploma in Real Estate Management and am a member of the Royal Institution of Chartered Surveyors (MRICS) since 2008. With extensive experience in national and international property transactions, ESG strategy development, and team management, I am dedicated to enhancing property value sustainably and strategically. My expertise includes managing due diligence processes, property valuation, and project development, ensuring comprehensive service and successful outcomes in the real estate sector.
  </p>
</div>
              </div>
              <div class="service">
                <div class="about_title">
                  <h3>
                    <span>Skills <span class="coloring">Overview</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(service, i) in aboutData.serviceLists" :key="i">
                      <i class="icon-right-dir"></i>{{ service }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span
                      >Specialist <span class="coloring">Skills</span></span
                    >
                  </h3>
                </div>
                <div class="oki_progress">
                  <div
                    class="progress_inner"
                    v-for="(skill, i) in aboutData.skills.programming"
                    :key="i"
                  >
                    <span
                      ><span class="label">{{ skill.name }}</span
                      ><span class="number">{{ skill.value }}</span></span
                    >
                    <div class="background">
                      <div class="bar open">
                        <!--<div
                          class="bar_in"
                          :style="{ width: `${skill.value}%` }"
                        ></div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      <!-- Language Skills Section -->
      <div class="lang_skill">
        <div class="about_title">
          <h3>
            <span>Language <span class="coloring">Skills</span></span>
          </h3>
        </div>
        <div class="circular_progress_bar">
          <ul>
            <li v-for="(language, i) in aboutData.skills.language" :key="`language-${i}`">
              <div class="list_inner">
                <div class="myCircle" :data-value="language.value / 100"></div>
                <div class="title"><span>{{ language.name }}</span></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
  <!-- Education Timeline Section -->
  <div class="timeline">
    <div class="about_title">
      <h3>
        <span>Education <span class="coloring">Timeline</span></span>
      </h3>
    </div>
    <div class="list">
      <ul>
        <li v-for="(edu, i) in aboutData.education" :key="i">
          <div class="list_inner">
            <div class="time">
              <span>{{ edu.year }}</span>
            </div>
            <div class="place">
              <h3>{{ edu.institution }}</h3>
              <span>{{ edu.degree }}</span>
              <span>{{ edu.description }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
    <!-- Working Timeline Section -->
    <div class="timeline">
    <div class="about_title">
      <h3>
        <span>Working <span class="coloring">Timeline</span></span>
      </h3>
    </div>
    <div class="list">
      <ul>
        <li v-for="(work, i) in aboutData.working" :key="i">
          <div class="list_inner">
            <div class="time">
              <span>{{ work.year }}</span>
            </div>
            <div class="place">
              <h3>{{ work.company }}</h3>
              <span>{{ work.position }}</span>
              <span>{{ work.description }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
      <!-- Associations Section -->
      <div class="associations">
        <div class="about_title">
          <h3>
            <span>Associations</span>
          </h3>
        </div>
        <div class="list">
          <ul>
            <li>
              <div class="list_inner">
                <span>Membership of Royal Institution of Chartered Surveyor since 02/2004</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Computer Skills Section -->
      <div class="computer_skills">
        <div class="about_title">
          <br><br>
          <h3>
            <span>Computer Skills</span>
          </h3>
        </div>
        <div class="list">
          <ul>
            <li>MS Office package</li>
            <li>Argus</li>
            <li>MS Project</li>
            <li>Primo</li>
          </ul>
        </div>
      </div>

       <!-- Overview of Competencies Section -->
  <div class="competencies">
    <br><br>
    <div class="about_title">
      <h3>
        <span>Overview of <span class="coloring">Competencies</span></span>
      </h3>
    </div>
    <div v-for="(category, categoryName) in aboutData.competencies" :key="categoryName" class="competency_category">
      <h6>{{ categoryName }}</h6>
      <ul>
        <li v-for="(item, index) in category" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>

 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AnimationText from "../AnimationText.vue";
export default {
  name: `AboutPopup`,
  components: {
    Swiper,
    SwiperSlide,
    AnimationText,
  },
  data() {
    return {
      open: false,
      // data
      aboutData: {
        firstName: "Irina",
        lastName: "Eschke",
        bithday: "Not specified",
        address: "Undinestraße 40, 12203 Berlin",
        phn: "+491796721809",
        email: "irina.eschke@yahoo.de",
        serviceLists: [
          "National / international property transactions",
          "ESG review for property value enhancement",
          "Team management and coordination",
          "Due diligence process management",
          "Property valuation and portfolio assessment",
          "Acquisition and re-financing management",
          "Project development and conversion projects"
        ],
        skills: {
          programming: [
            // Assuming these are professional skills, not programming
            { name: "Transaction Management", value: "Germany, France, Spain" },
            { name: "Due Diligence", value: "Buying and Selling Processes" },
            { name: "ESG Audits", value: "Strategy Development" },
          ],
          language: [
            { name: "German", value: 100 },
            { name: "English", value: 100 },
            { name: "Russian", value: 20 },
            { name: "Spanish", value: 20 },
          ],
        },
        education: [
          {
            year: "03/2021 - 06/2021",
            institution: "EBS Finance Academy",
            degree: "SRI Advisor",
            description: "Sustainable & Responsible Investment - SRI"
          },
          {
            year: "10/1998 - 09/2002",
            institution: "HTW Berlin",
            degree: "Real Estate Business Management",
            description: "Degree in real estate business management, Overall rating: 'good'"
          },
          {
            year: "08/1991 - 06/1998",
            institution: "Grammar School, Berlin",
            degree: "Abitur",
            description: ""
          },
        ],
        working: [
          {
            year: "04/2023 - 10/2023",
            company: "Catella Residential Investment Management GmbH",
            position: "Senior Investment Manager",
            description: "Set-up the Value-Add investment strategy, Identification and acquisition of investment opportunities in line with strategy, taking into account value-add aspects through energy-efficient refurbishment, Management of entire investment process until closing of transaction, Management of entire property economic, legal and technical due diligence process"
          },
          {
            year: "04/2022 - 06/2022",
            company: "DLE Living GmbH",
            position: "Senior Investment Manager and Team Leader",
            description: "Responsible for the management of the entire investment process to purchase and sale properties, Addressing investors for DLE Living funds (pension funds, insurance companies, banks etc.), Team leadership: recognising team competencies and potential; structuring and managing tasks to achieve the set goals"
          },
          {
            year: "02/2015 - 03/2022",
            company: "Aquila Capital Management GmbH",
            position: "Senior Investment Manager",
            description: "Organisation of the investment team and management of the complete investment process up to transaction's closing, Identification and acquisition of investment opportunities in line with company strategy, Management of the entire economic, legal, tax and technical due diligence process, Market analysis and assessment for the respective asset class (office, residential, logistics, retail)"
          },
          {
            year: "10/2014 - 01/2015",
            company: "Ecolog International Germany GmbH",
            position: "Freelancer",
            description: "Consultancy work, including the development of a structuring/marketing concept for the Düsseldorf Business Club"
          },
          {
            year: "06/2002 - 09/2014",
            company: "BNP Paribas Real Estate Consult GmbH",
            position: "Senior Consultant",
            description: "Acquisition of valuation mandates, support of existing clients, Project management for large-volume valuation projects, Preparation of expert opinions for real estate portfolios and single properties of all asset classes"
          },
        ],
        partnersLogos: [
          // Assuming these are references or selected references
          "img/partners/reference1.png",
          "img/partners/reference2.png",
          "img/partners/reference3.png",
          "img/partners/reference4.png",
        ],
        competencies: {
          'Professional Skills': [
            "Transaction experience in Germany, France, and Spain",
            "Management of due diligence processes for buying and selling purposes",
            "Execution of ESG audits as well as strategy development for active and sustainable value enhancement of properties",
            "Responsibility and supervision of project development for new and existing properties",
            "Valuation services for single properties and real estate portfolios according to international and national standards",
            "Advisory services for real estate",
            "Property, location, and market analyses",
            "Identification of existing strengths, weaknesses, risks, and opportunities for optimisation",
            "Project-oriented target group analyses",
            "Creation of marketing concepts",
            "Research activities (market reports, market outlook)",
            "Preparation of expert opinions in German / English language",
            "Activity focus: logistics and residential",
            // ... more professional skills
          ],
          'Selected References': [
            "Participation in the acquisition process of an 84,000 m² logistics property for a Korean consortium and an investment volume of EUR 120 million in Worms, Germany",
            "Management of the acquisition process of a plot of land for the development of an 82,000 m² logistics and business park with an investment volume of EUR 172 million near Munich, Germany",
            "Management of the acquisition process of a plot for the development of a 36,000 m² temperature-controlled logistics hall with an investment volume of EUR 34 million near Bordeaux, France",
            "Participation in the sale process of a Spanish residential portfolio with approx. 102,000 m² and an investment volume of around EUR 275 million to a Dutch pension fund",
            "Management of the refinancing process for a hotel in Madrid, Spain by a German mortgage bank and a financing volume of EUR 20 million",
            "Project management for the evaluation of a residential and commercial property portfolio with 72 properties in Berlin and Potsdam for internal purposes"
          ],
          'Personal Characteristics': [
            "Strong analytical and conceptual thinking",
            "High level of commitment and reliability",
            "Excellent communication skills",
            "Team-oriented leadership style",
            // ... more personal characteristics
          ],
        },
      },
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    const aboutPopup = document.getElementById("aboutPopup");
    aboutPopup.addEventListener("click", () => {
      this.open = true;
    });

    const myCircle = document.querySelectorAll(".myCircle");
    myCircle.forEach((circle) => {
      let progress = circle.getAttribute("data-value");
      let span = document.createElement("span");
      span.className = "number";
      const value = `${progress * 100}%`;
      span.innerHTML = value;
      circle.appendChild(span);
      circle.style.setProperty("--progress", value);
      circle.setAttribute("aria-valuenow", value);
    });
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.testimonial {
  overflow: hidden;
}
.owl-carousel.swiper {
  overflow: unset;
}

.myCircle {
  --hue: 220;
  --holesize: 67%;
  --track-bg: hsl(233, 34%, 92%);

  min-width: 110px;
  min-height: 110px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}
.myCircle::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  background: conic-gradient(
    var(--main-color) var(--progress, 0%),
    rgba(255, 255, 255, 0.15) var(--progress, 0%) 100%
  );

  mask-image: radial-gradient(
    transparent var(--holesize),
    black calc(var(--holesize) + 1px)
  );
}
</style>
