<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/irina.png" alt="Irina Eschke" />
            <div class="main" data-img-url="img/about/irina.png"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">Irina <span class="coloring">Eschke</span></h3>
            <h3 class="job">
              <AnimationText />
            </h3>
          </div>
          <div class="text">
            <p>
              My name is <span>Irina Escke.</span> I am a seasoned professional with a background in 
              Property Investment Management and Sommelier Expertise. 
              My journey includes achievements such as obtaining a Sommelier certification from IHK and WSET Level 2, 
              conducting wine tastings, and contributing to the cuvée of prestigious wines. 
              My extensive travels have enriched my knowledge of wines from various regions, making me a specialist in 
              German, Austrian, Northern Italian, Spanish, and South African wines. With a passion for both worlds, 
              I bring a unique blend of property investment acumen and wine mastery to my endeavors.            </p>
          </div>
          <div class="edrea_tm_button" id="aboutPopup">
            <a href="#">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
